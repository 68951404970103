@charset "UTF-8";

$icon-font-path: "../../fonts/";
@import "css/variables";

$arvoFontPath: "./";
@import "arvo";
$ptSansFontPath: "./";
@import "ptsans";

$contentBorderRadius: 0;

$colorLinksLight: #6d7e00;
$colorLinks: #6d7e00;
$colorLinksFooter: $colorLinks;
$linkTextDecoration: none;
$colorDelLink: #FF7777;
$colorMagenta: rgb(226, 0, 122);
$brand-primary: $colorMagenta;
$text-color: rgb(72, 70, 73);
$btn-success-bg: #2c882c;
$label-success-bg: $btn-success-bg;

$headingFont: "Arvo", sans-serif;
$headingPrimaryText: rgb(255, 255, 255);
$headingPrimaryBackground: #0a321e;
$headingSecondaryText: rgb(255, 255, 255);
$headingSecondaryBackground: rgb(175, 203, 8);
$headingTertiaryText: black;
$headingTertiaryBackground: rgb(27, 74, 251);

$menuFont: "Arvo", sans-serif;
$menuLink: #6d7e00;
$menuActive: rgb(115, 115, 115);

$sidebarBackground: $colorMagenta;
$sidebarActionFont: "Arvo", sans-serif;

$bookmarkAmendmentBackground: $colorGreenLight;
$bookmarkCommentColor: $colorMagenta;

$bodyFont: "PT Sans", "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$deadlineCircleFont: "Arvo", sans-serif;
$sidebarActionFont: "Arvo", sans-serif;
$buttonFont: "Arvo", sans-serif;
$motionFixedFont: 'PT Sans', Courier, sans-serif;
$motionFixedFontColor: black;
$motionFixedWidth: 733px;
$motionStdFontSize: 16px;
$inlineAmendmentPreambleHeight: 35px;
$inlineAmendmentPreambleColor: $colorMagenta;
$createMotionBtnColor: $colorMagenta;
$homeAgendaTitlePrefixWidth: 110px;

$screen-md-min: $container-md !default;
$mainContentWidth: $container-md - $sidebarWidth !default;
$content-max-width: $mainContentWidth - 2px !default;
$grid-float-breakpoint: $container-md - 32px !default; // $screen-md-min;

@import "css/bootstrap";
@import "css/fontello";
@import "css/wizard";
@import "css/helpers";
@import "css/elements";
@import "css/bootstrap_overwrites";
@import "css/base_layout";
@import "css/contentpage";
@import "css/consultation_motion_list";
@import "css/speech_lists";
@import "css/voting";
@import "css/motions";
@import "css/proposed_procedure";
@import "css/styles";
@import "css/merging";
@import "css/projector";
@import "css/user_pages";

body {
  background-image: url(gruenes_ci2_background.jpg);
  background-repeat: repeat;
  background-position: top center;
  background-attachment: fixed;
}

.logoImg {
  display: block;
  width: 89px;
  height: 87px;
  background-image: url("logo_gruene-2015.png");
}

.well h1 {
  font-family: "Arvo Gruen", sans-serif;
  font-weight: normal;
}

.motionListStd .motion .title a {
  font-family: "Arvo", sans-serif;
}

#mainmenu {
  display: block;
  background: #0a321e;
  text-align: right;
  padding: 0.5em 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
  position: relative;

  .navbar {
    min-height: 36px;
    .nav li a {
      &, &:visited, &:link {
        color: white;
        font-family: $bodyFont;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;
        line-height: 36px;
        vertical-align: middle;
      }
    }
  }

  .navigation li.addPage {
    float: right;
  }

  .navbar-nav {
    margin: 7.5px 0 7.5px 15px;
  }

  .navbar-inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .unsichtbar {
    display: none;
  }
  .navigation {
    background: #0a321e;
    font-size: 20.8px;
    font-family: 'Arvo', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    ul {
      list-style-type: none;
    }
    li {
      display: block;
      float: left;
      position: relative;
      padding: 0;
      margin: 0;
    }
    a, a:link, a:visited {
      padding: 1em;
      display: block;
      background: transparent;
      color: #fff;
      border: 0;
    }
  }
}

.row.logo {
  margin-bottom: 1em;
  position: relative;
  min-height: 110px;

  #logo {
    display: inline-block;
    width: 20%;
    z-index: 2;
    position: relative;
    margin-top: 0.5em;
  }

  .hgroup {
    display: inline-block;
    width: auto!important;
    text-align: right;
    z-index: 2;
    position: absolute;
    top: 10%;
    right: 0;
    border-top: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    padding: 0.5em 0;
    @media screen and (max-width: 550px) {
      display: none;
    }
  }
  a, a:link, a:visited {
    color: #fff;
    text-decoration: none;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  }
  #site-title, #site-description {
    padding: 0 0 0 5px;
    margin: 0;
    line-height: 1em;
    display: block;
    text-align: right;
  }
  #site-title {
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    text-transform: uppercase;
    font-family: 'Arvo Gruen','Arvo', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-size: 40px;
  }

  #site-description {
    text-align: right;
    margin-top: 0.5em;
    font-size: 18px;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    color: #ffe000;
    font-family: 'Arvo', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  }
}

.logoRow {
  margin: 0;
  position: relative;
  min-height: 110px;

  #logo {
    display: inline-block;
    width: 20%;
    z-index: 2;
    position: relative;
    margin-top: 0.5em;
  }

  .hgroup {
    display: inline-block;
    width: auto!important;
    text-align: right;
    z-index: 2;
    position: absolute;
    top: 28px;
    right: 0;
    border-top: 0.2em solid #fff;
    border-bottom: 0.2em solid #fff;
    padding: 0.5em 0;
    @media screen and (max-width: 550px) {
      display: none;
    }
  }
  a, a:link, a:visited {
    color: #fff;
    text-decoration: none;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  }
  #site-title, #site-description {
    padding: 0 0 0 5px;
    margin: 0;
    line-height: 1em;
    display: block;
    text-align: right;
  }
  #site-title {
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    text-transform: uppercase;
    font-family: 'Arvo Gruen','Arvo', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-size: 40px;
  }

  #site-description {
    text-align: right;
    margin-top: 0.5em;
    font-size: 18px;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    color: #ffe000;
    font-family: 'Arvo', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  }
}

.navwrap {
  margin-bottom: 2em;
}

.breadcrumb {
    display: block;
    z-index: 2;
    position: relative;
    background: #ffee00;
    color: #0a321e;
    padding: 0.3em 1em;
    font-size: 14.4px;
    font-family: 'PT Sans Bold', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    text-transform: none;
    margin: 0;
    color: #0a321e;
    a, a:link, a:visited {
      color: #0a321e;
    }
}

footer.footer {
  text-align: center;
  font-size: 12.8px;
  color: white;
  padding: 1em;
  margin-bottom: 30px;

  .version {
    font-size: 12.8px;
  }

  a, a:link, a:visited {
    color: #ffe000;
  }
}

.well {
  border-radius: 0;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
}

@media (min-width: $screen-md) {
  main.col-md-9 {
    width: 750px;
  }
  aside.col-md-3 {
    width: 274px;
  }
}

#sidebar {
  font-size: 16px;
  padding-right: 0;
  padding-left: 50px;

  .form-search {
    margin-bottom: 2em;

    .invisible, label {
      display: none;
    }
    .query {
      padding: 1em 5%;
      border: 1px solid #0a321e;
      width: 100%;
    }
    button {
      border: 0;
      background: #0a321e;
      color: #fff;
      font-family: 'Arvo', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
      padding: 0.5em 1em;
      margin-top: 1em;
      font-size: 1em;
      font-weight: normal;
    }

    @media only screen and (min-width: 770px) {
     .query {
        box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
      }
      button {
        box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
      }
    }
  }
  .sidebar-box, .sidebarActions {
    margin: 0 0 2em 0;
    padding: 18px;
    border: 0;
    background: #e6e6e6;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
    border-radius: 0;
    list-style-type: none;

    a {
        padding: 10px 0;
        display: block;
        &:hover {
          background-color: #eee;
        }
        .icon:before {
          margin-right: 0.2em;
        }
    }

    li a, .icon {
      color: #46962b;
    }


    button {
      color: #46962b;
      text-align: left;
      padding: 10px 0;
      &:hover {
        background-color: #eee;
      }
    }
  }
  .nav-list {
    margin-bottom: 0;
  }
  .nav-list>li.nav-header {
    padding: 5px 0 5px 0;
    color: #0a321e;
    font-size: 20px;
    font-family: 'Arvo', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  }
  .nav-list>li {
    padding: 0;
  }
  .nav > li > a {
    padding: 10px 0;
  }

  .createMotionHolder2 {
    margin-bottom: 2em;
    .createMotion {
      display: block;
      box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
      border: 0;
      background: #0a321e;
      color: #fff;
      font-family: 'Arvo', 'Arvo Gruen', Trebuchet, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
      padding: 0.5em 1em;
      margin-top: 1em;
      font-size: 1em;
      font-weight: normal;
      span {
        margin-right: 0.5em;
      }
    }
  }

  .share_buttons {
    margin-top: 20px;
    li {
      width: 102px;
    }
  }
}
